import "./App.css";
import React from "react";
import Navbar from "./Components/Navbar";
import "./js/cursor";
import Home from "./Components/Home";
import About from "./Components/About";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";
import NotFound from "./Components/Not-Found";
import { Route, Switch, Redirect } from "react-router-dom";

function App() {
	return (
		<React.Fragment>
			<Navbar />
			<main className="container">
				<Switch>
					<Route path="/home" component={Home} />
					<Route path="/about" render={(props) => <About {...props} />} />
					<Route path="/projects" render={(props) => <Projects {...props} />} />
					<Route path="/contact" render={(props) => <Contact {...props} />} />
					<Redirect from="/" to="/home" />
					<Route path="" component={NotFound} />
					{/* <Redirect from="" to={NotFound} /> */}
				</Switch>
			</main>
		</React.Fragment>
	);
}

export default App;
