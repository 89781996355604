import React, { Component } from "react";
import "./cursor";
import "react-toastify/dist/ReactToastify.css";

class Home extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="row home">
					<div className="col">
						<h1 className="Name">
							<span className="first">O</span>
							<span className="first">t</span>
							<span className="first">h</span>
							<span className="first">m</span>
							<span className="first">a</span>
							<span className="first">n</span> <span className="last">A</span>
							<span className="last">l</span>
							<span className="last">i</span>
						</h1>
					</div>
					<div className="col text">
						<h5>
							Pragmatic and Innovative Programmer <br></br> and Web Developer
							from Ramallah,{" "}
							<span className="ramallah">
								<span>P</span>
								<span>a</span>
								<span>l</span>
								<span>e</span>
								<span>s</span>
								<span>t</span>
								<span>i</span>
								<span>n</span>
								<span>e</span>.
							</span>
						</h5>
					</div>
				</div>
				<script src="cursor"></script>
			</React.Fragment>
		);
	}
}

export default Home;
