import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
	render() {
		return (
			<nav className="navbar">
				<li>
					<Link to="/">Home</Link>
				</li>
				<li>
					<Link to="/about">About Me</Link>
				</li>
				<li>
					<Link to="/projects">Projects</Link>
				</li>
				<li>
					<Link to="/contact">Contact</Link>
				</li>
			</nav>
		);
	}
}

export default Navbar;
