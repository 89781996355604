import React, { Component } from "react";
import image from "./img/newoth.webp";

class About extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="row about">
					<div className="col bord -2">
						<img className="pic" src={image} alt="" />
					</div>
					<div className="col parag -3">
						<div class="line-5"></div>
						<span className="hi">Hello!</span> my name is Othman, I am a
						Computer Scientist and Web Developer from Ramallah. My programming
						journey started when I was in high school, that is when I made a
						racing car video game on Unity3D, since then I became very
						passionate about writing code and creating something beautiful with
						it. I believe that nowadays since it became more challenging for
						tech companies to go from Zero to One (create a breakthrough), it is
						necessary to have a keen eye for ideas that can potentially make a
						difference. I aspire to someday work on such projects. Building user
						interfaces is what I love to do. Currently, I am a fourth year
						Computer Science major at{" "}
						<a
							href="https://www.birzeit.edu/en"
							target="_blank"
							className="bzu"
							rel="noreferrer"
						>
							Birzeit University
						</a>
						.
					</div>
				</div>
				<div className="row skill">
					<div className="list">
						<span className="hi hhh">Skills</span>

						<div>
							<h3>Frontend</h3>
							<span className="bubble">Reactjs</span>
							<span className="bubble">Vuejs</span>
							<span className="bubble">HTML/CSS</span>
							<span className="bubble">JavaScript</span>
							<span className="bubble">SEO</span>

							<span className="bubble">UX</span>
						</div>
						<div class="vl"></div>

						<div>
							<h3>Backend</h3>
							<span className="bubble">Java</span>
							<span className="bubble">Nodejs</span>
							<span className="bubble">Python</span>
							<span className="bubble">Django</span>
							<span className="bubble">Express</span>
							<span className="bubble">Mongodb</span>
							<span className="bubble">Postman</span>
						</div>
						<div class="vl"></div>

						<div>
							<h3>Other</h3>
							<span className="bubble">Linux</span>
							<span className="bubble">Github</span>
							<span className="bubble">Git</span>
							<span className="bubble">Cmd</span>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default About;
