import React, { Component } from "react";

class Projects extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="container projects">
					<div
						onClick={() => {
							window
								.open(
									"https://github.com/Othmanali02/License-Plate-Recognition-Infinite",
									"_blank"
								)
								.focus();
						}}
						className="col proj"
					>
						<h1>ALPR (Using Raspberry Pi 3b+, Python)</h1>
					</div>

					<div
						onClick={() => {
							window.open("https://saleem-ps.com", "_blank").focus();
						}}
						className="col proj proj-secondary"
					>
						<h1>E-Commerce Website(MERN Stack)</h1>
					</div>

					<div
						onClick={() => {
							window.open("https://hangle-geo.com", "_blank").focus();
						}}
						className="col proj"
					>
						<h1>Hangle - Hangman for Countries</h1>
					</div>

					<div
						onClick={() => {
							window
								.open(
									"https://github.com/Othmanali02/Scientific-Calculator",
									"_blank"
								)
								.focus();
						}}
						className="col proj proj-secondary"
					>
						<h1>Scientific Calculator (Java, JavaFX)</h1>
					</div>

					<div
						onClick={() => {
							window.open("https://anagram-scramble.com/", "_blank").focus();
						}}
						className="col proj"
					>
						<h1>Scramble - The Daily Anagram Game</h1>
					</div>

					<div
						onClick={() => {
							window
								.open("https://theplugforspotify.netlify.app", "_blank")
								.focus();
						}}
						className="col proj  proj-secondary"
					>
						<h1>Spotify Statistics WebApp</h1>
					</div>

					<div
						onClick={() => {
							window
								.open("https://github.com/Othmanali02/To-Do-List", "_blank")
								.focus();
						}}
						className="col proj"
					>
						<h1>To Do List (Vanilla Javascript)</h1>
					</div>

					<div
						onClick={() => {
							window
								.open(
									"https://github.com/Othmanali02/Ecommerce-Media-Rental",
									"_blank"
								)
								.focus();
						}}
						className="col proj proj-secondary"
					>
						<h1>Media Rental Application (Java, Javafx)</h1>
					</div>

					<div
						onClick={() => {
							window
								.open("https://dijkstra-world-map.netlify.app/", "_blank")
								.focus();
						}}
						className="col proj"
					>
						<h1>World Map Navigator (Shortest Path)</h1>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Projects;
